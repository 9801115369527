<template>
  <v-row class="form-admin">
    <v-col cols="4">
      <v-form>
        <h1>ADMINISTRADOR</h1>
        <div class="form-label">
          <h2>Correo</h2>
        </div>
        <v-text-field
          class="form-text ml-5"
          :rules="[(v) => !!v || 'El correo electrónico es requerido.']"
          color="white"
          dark
          regular
          placeholder="Tu correo"
          prepend-inner-icon="mdi-account-circle-outline"
          v-model="form.email"
        >
        </v-text-field>
        <div class="form-label">
          <h2>Contraseña</h2>
        </div>
        <v-text-field
          class="form-text ml-5"
          :rules="[(v) => !!v || 'La contraseña es requerida.']"
          dark
          regular
          placeholder="Tu contraseña"
          prepend-inner-icon="mdi-onepassword left"
          :type="show ? 'text' : 'password'"
          :append-icon="show ? 'mdi-eye-off' : 'mdi-eye'"
          @click:append="show = !show"
          v-model="form.pass"
        >
        </v-text-field>
        <v-btn
          class="form-btn ml-2 mr-2 mt-5"
          block
          rounded
          color=""
          :loading="loading"
          @click="login()"
        >
          ACCERDER
        </v-btn>
      </v-form>
    </v-col>
  </v-row>
</template>
<script>
import Api from "@/config/api";
// import { login } from "../../config/auth";
import swal from "@/mixins/swal";

export default {
  mixins: [swal],
  data() {
    return {
      show: false,
      loading: false,
      form: {
        email: "",
        pass: "",
      },
    };
  },
  watch: {},
  methods: {
    async login() {
      this.loading = true;
      let data = {
        email: this.form.email,
        password: this.form.pass,
        rememberMe: true,
      };
      console.log("va a consumir");
      await Api.Auth()
        .login(data)
        .then((res) => {
          if (res.data.cod == 0) {
            localStorage.setItem("access_token", res.data.data.access_token);
            this.swal("Logueado correctamente", "success");
          } else {
            this.swal("No tiene autorización", "error");
          }
        })
        .catch((error) => {
          this.swal(error.response.data.message, "error");
        });
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.form-admin {
  background: linear-gradient(315deg, #485172 20%, #282f45 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-label {
  color: #fff;
  text-align: left;
}
.form-btn {
  font-weight: bold;
}
h1 {
  color: #fff;
  padding-bottom: 50px;
}
</style>
